<template>
  <div id="partners">
    <b-container>
      <b-row class="content">
        <b-col>
          <h3  class="title" >
            Partners
          </h3>
        </b-col>
      </b-row>
      <template v-if="isMobile(true)">
        <b-row class="SponRow" >
            <b-col class="sponBack flip-cardMob" v-for="(spon, index) in part" :key="index"  cols='6'>
              <a :href="'/individual-partners/' + spon.acf.name">
                <div class="flip-card-inner">
                    <div class="flip-card-front">
                        <b-img class="sponImg" :src="spon.acf.white_logo"></b-img>
                    </div>
                    <div class="flip-card-back">
                        <!-- <a :href="'/individual-partners/' + spon.acf.name"> -->
                            <div class="learnMore">
                                Find Out More
                            </div>
                        <!-- </a> -->
                    </div>
                </div>
              </a>
            </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row class="SponRow" >
            <b-col class="sponBack flip-card" v-for="(spon, index) in part" :key="index"  cols lg="3" md="3" sm="12">
                <div class="flip-card-inner">
                    <div class="flip-card-front">
                        <b-img class="sponImg" :src="spon.acf.white_logo"></b-img>
                    </div>
                    <div class="flip-card-back">
                        <a :href="'/individual-partners/' + spon.acf.name">
                            <div class="learnMore">
                                Find Out More
                            </div>
                        </a>
                    </div>
                </div>
            </b-col>
        </b-row>
      </template>
      <!-- <b-row v-for="(spon, index) in part" :key="index" class="content">
        <b-col>
          <b-row class="sponTitle">
            <b-col cols="4" class="mobDisplay">
              <b-img class="sponImg" :src="spon.acf.white_logo"></b-img>
            </b-col>
            <b-col cols="8" class="mobDisplay">
              <a :href="spon.acf.sponsor_url">
                <span v-html="spon.title.rendered" class="partnerTitle"></span>
              </a>
              <span class="blurb" v-html="spon.content.rendered"></span>
            </b-col>
          </b-row>
          <b-row>
          </b-row>
        </b-col>
      </b-row> -->
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "partners",
  props: ["develop", 'config'],
  data() {
    return {
      part: []
    };
  },
  computed: {
    setDev: function() {
      console.log(this.$route.name)
      if (this.develop === "apga" && this.$route.name === 'destinations-partners')
        return process.env.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/destinationspartners?_embed";
        else if (this.develop === 'apga')
          return process.env.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/Sponsors?_embed&randomadd=" +
                new Date().getTime();
      else
        return process.env.VUE_APP_WP_ADT_API_URL + "wp/v2/Sponsors?_embed";
    }
  },
  methods: {
    isMobile() {
      if( screen.width <= 990 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
    axios
      .get(this.setDev)
      .then(response => {
        this.part = response.data;
      });},1000)
  }
};
</script>

<style scoped>
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
}
.flip-cardMob {
    background-color: transparent;
    width: 300px;
    height: 210px;
    perspective: 1000px;
}
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}
.learnMore {
    color: #fff;
    border: 1px solid;
    margin-top: 40%;
    width: 70%;
    text-align: center;
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
}
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 247px;
    /* height: 255px; */
    /* height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    /* background-color: #bbb; */
    color: black;
}

.flip-card-back {
    background-color: #042F62;
    color: white;
    transform: rotateY(180deg);
}
.partnerTitle {
  font-weight: 500;
  font-size: 18px;
}
#partners {
  background-color: #ebebeb;
}
.title {
  color: #0A3F7F;
  font-weight: 500;
  font-size: 20px!important;
  margin-top: 50px;
  background: transparent!important;
  text-align: left;
}
.logo {
  width: 120px;
  float: right;
}
.content {
  padding-bottom: 3%;
  padding-top: 3%;
}
.sponImg {
  width: 100% !important;
}
.sponTitle {
  padding-bottom: 2%;
  font-size: 14pt;
}
a {
  color: #0b3f7e !important;
}
.blurb {
  font-size: 14px;
  color: #707070;
  margin-top: 25px;
  display: block;
}
.develop{
  color: #76c3bd !important;
}

@media only screen and (max-width: 990px) {
  .mobDisplay {
    flex: 100%;
    max-width: 100%;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 425px) {
  .flip-cardMob {
      background-color: transparent;
      width: 300px;
      height: 180px;
      perspective: 1000px;
  }
}
</style>
